import {afterEachHandler, beforeEachHandler, commonRoutes, onError} from '@jetCommon/router.js';
import {api} from '@/api';
import {createRouter, createWebHashHistory} from 'vue-router';
import {scrollBehavior} from '@jetCommon/router.js';
import {useSpaStore} from '@/stores/spa.js';
import settings from '@/env.js';

const routes = [
    ...commonRoutes,
    {
        path: '/registrazione/:specialLinkCode',
        component: () => import('@/views/FirstAccessView.vue'),
        meta: {requiresAuth: false},
        name: 'firstAccessView',
    },
    {
        path: '/completa-assunzione/:hiringUUID',
        component: () => import('@/features/hiring/views/HiringContractView.vue'),
        meta: {requiresAuth: false},
        name: 'hiringContractView',
    },
    {
        path: '/completa-assunzione/link-scaduto',
        component: () => import('@/features/hiring/views/HiringExpiredLinkView.vue'),
        meta: {requiresAuth: false},
        name: 'hiringContractExpiredView',
    },
    {
        path: `/:${settings.JET_ROUTE_ENTITY_NAME}?`,
        component: () => import('@/layouts/SidebarLayout.vue'),
        children: [
            {
                path: '',
                component: () => import('@/views/DashboardView.vue'),
                name: 'dashboardView',
            },
            {
                path: 'assenze',
                component: () => import('@/views/AbsenceRequestsView.vue'),
                name: 'absenceRequestsView',
            },
            {
                path: 'timbrature',
                component: () => import('@/features/clocking/views/ClockingsView.vue'),
                name: 'clockingsView',
            },
            {
                path: 'assenze-calendario',
                component: () => import('@/views/AbsenceCalendarView.vue'),
                name: 'absenceCalendarView',
            },
            {
                path: 'rimborsi-spese',
                component: () => import('@/features/expense/views/ExpensesView.vue'),
                name: 'expensesView',
            },
            {
                path: 'documenti',
                component: () => import('@/views/DocumentsView.vue'),
                name: 'documentsView',
            },
            {
                path: 'impostazioni',
                component: () => import('@/views/SettingsView.vue'),
                name: 'settingsView',
            },
            {
                path: 'team',
                component: () => import('@/views/ManagerTeamView.vue'),
                name: 'managerTeamView',
            },
            {
                path: 'team/:teamEmployeeId(\\d+)',
                component: () => import('@/views/ManagerTeamEmployeeDetailView.vue'),
                name: 'managerTeamEmployeeDetailView',
            },
            {
                path: 'approvazioni-assenze',
                component: () => import('@/views/ManagerAbsencesRequestsView.vue'),
                name: 'managerAbsencesView',
            },
            {
                path: 'approvazioni-spese',
                component: () => import('@/features/expense/views/ManagerExpensesView.vue'),
                name: 'managerExpensesView',
            },
            {
                path: ':pathMatch(.*)*',
                redirect: () => '/404',
            },
        ],
    },
];

const router = createRouter({
    scrollBehavior,
    history: createWebHashHistory(),
    routes,
});

router.beforeEach(
    async (to, from) => await beforeEachHandler(to, from, api, useSpaStore, settings.JET_ROUTE_ENTITY_NAME),
);

router.afterEach(afterEachHandler);

router.onError(onError);

export default router;
