import {DataStoreApiBase} from '@jetCommon/data-stores-api/base.js';

export default class CountriesApiCommon extends DataStoreApiBase {
    static resourceName = 'countries';

    getCountriesChoices() {
        return this.apiGet('countries_choices/');
    }

    getEuCountryCodes() {
        return this.apiGet('eu_country_codes/');
    }
}
